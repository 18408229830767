import { SetStateAction } from "react"
import IUser from "../models/user"

export const person_number = (user:IUser, setNumberPerson:React.Dispatch<SetStateAction<number>>) => {
    let person = 0
    if(user.achatCouple === undefined || user.dependent === undefined) return person

    //1 person
    if(!user.achatCouple && user.dependent) {
        //X dependent persons + 2 (one person & bonus)
        person = Number(user.dependentNumber) + 2 
    }
    if(!user.achatCouple && !user.dependent) {
        user.disableMe ? 
            //1 (person) + 1 (disable) 
            person = 2 
            // else 1
            : person = 1
    }
    
    //2 persons
    // 4 dependent persons (= max point number)
    if(user.achatCouple && user.dependent && Number(user.dependentNumber) === 4) person = 6
    // dependent person between 1 & 3
    if(user.achatCouple && user.dependent && Number(user.dependentNumber) < 4) {
       user.disable 
        //X dependent persons + 2 (persons) + 1 (disable)
        ? person = 1 + 2 + Number(user.dependentNumber) 
        //X dependent persons + 2 (persons)
        : person = 2 + Number(user.dependentNumber)
    }
    if(user.achatCouple && !user.dependent) {
        user.ageSum 
            //2 (persons) + 1 (ageSum > 55) 
            ? person = 3 
            : user.disable
                //2 (persons) + 1 (disable) 
                ? person = 3 
                //else 2
                : person = 2 
    }
    setNumberPerson(person)
}