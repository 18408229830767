import { StepsProvider } from "react-step-builder";
import EligibilteSteps from "./components/EligibilteSteps"

import './App.css';

export default function App() {
  return (
    <div className="App">
      <header className="AppHeader">
        <StepsProvider>
          <EligibilteSteps />
        </StepsProvider>
      </header>
    </div>
  );
}
