import { ChangeEvent, useEffect, useState } from "react";
import { Steps, useSteps } from "react-step-builder";
import Modal from 'react-modal'

import IUser from "../models/user"
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

export default function EligibilteSteps() {
    const { next, prev, jump } = useSteps();
    const [user, setUser] = useState<IUser>({ villes: [], rayonDeRecherche: 0, rgpdChecked: false});
    const [numberPerson, setNumberPerson] = useState(0)

    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    // reset user data ans go back to the first step
    const reset = () => {
        setUser({ villes: [], rayonDeRecherche: 1,rgpdChecked: false, dependent:undefined, dependentNumber: undefined, disableMe:undefined, disable:undefined, ageSum:undefined, achatCouple:undefined});
        jump(1)
    }

    return (
        <div style={{ width: '100%' }}>
            <Steps>
                <Step1 nextHandler={next} changeHandler={handleChange} user={user} setUser={setUser} setNumberPerson={setNumberPerson} />
                <Step2 previousHandler={prev} nextHandler={next} changeHandler={handleChange} user={user} />
                <Step3 nextHandler={next} user={user} reset={reset} numberPerson={numberPerson} />
                <Step4 changeHandler={handleChange} nextHandler={next} user={user} reset={reset} setUser={setUser} />
                <Step5 />
            </Steps>
        </div>
    )

}