export const DEPARTEMENTS: {[codedep:string]:{label:string, A:any, ABis?:any, B1:string[]}}= {
    '77': {
        label: 'Seine-et-Marne',
        A :[
            'Avon', 'Bailly-Romainvilliers', 'Boissettes', 'Boissise-la-Bertrand', 'Boissise-le-Roi', 'Brie-Comte-Robert', 'Brou-sur-Chantereine', 'Bussy-Saint-Georges', 'Bussy-Saint-Martin', 'Carnetin', 'Cesson', 'Chalifert', 'Champs-sur-Marne', 'Chanteloup-en-Brie', 'Chelles', 'Chessy', 'Claye-Souilly', 'Collégien', 'Combs-la-Ville', 'Conches-sur-Gondoire', 'Condé-Sainte-Libiaire', 'Couilly-Pont-aux-Dames', 'Coupvray', 'Courtry', 'Coutevroult', 'Crécy-la-Chapelle', 'Crégy-lès-Meaux', 'Croissy-Beaubourg', 'Dammarie-les-Lys', 'Dammartin-en-Goële', 'Dampmart', 'Émerainville', 'Esbly', 'Ferrières-en-Brie', 'Fontainebleau', 'Gouvernes', 'Gretz-Armainvilliers', 'Guermantes', 'Héricy', 'Isles-lès-Villenoy', 'Jossigny', 'Lagny-sur-Marne', 'Lésigny', 'Lieusaint', 'Livry-sur-Seine', 'Lognes', 'Longperrier', 'Magny-le-Hongre', 'Meaux', 'Le Mée-sur-Seine', 'Melun', 'Mitry-Mory', 'Moissy-Cramayel', 'Montry', 'Montévrain', 'Nandy', 'Nanteuil-lès-Meaux', 'Noisiel', 'Ozoir-la-Ferrière', 'Poincy', 'Pomponne', 'Pontault-Combault', 'Pringy', 'Quincy-Voisins', 'La Rochette', 'Roissy-en-Brie', 'Rubelles', 'Saint-Fargeau-Ponthierry', 'Saint-Germain-sur-Morin', 'Saint-Thibault-des-Vignes', 'Samoreau', 'Savigny-le-Temple', 'Seine-Port', 'Serris', 'Servon', 'Thorigny-sur-Marne', 'Torcy', 'Tournan-en-Brie', 'Trilport', 'Vaires-sur-Marne', 'Vaux-le-Pénil', 'Vert-Saint-Denis', 'Villenoy', 'Villeparisis', 'Villiers-sur-Morin', 'Voulangis', 'Vulaines-sur-Seine'
        ],
        B1: [
            'Annet-sur-Marne','Bagneaux-sur-Loing','Barbizon','Bois-le-Roi','Cannes-Écluse','La Celle-sur-Morin','Cély','Chailly-en-Bière','Chamigny','Champagne-sur-Seine','Chartrettes','Chevry-Cossigny','Coulommiers','Faremoutiers','Férolles-Attilly','La Ferté-sous-Jouarre','Fleury-en-Bière','Fontaine-le-Port','Jouarre','Mareuil-lès-Meaux','Montereau-Fault-Yonne','Moret-Loing-et-Orvanne','Mouroux','Moussy-le-Neuf','Nemours','Chauconin-Neufmontiers','Othis','Perthes','Le Pin','Pommeuse','Pontcarré','Provins','Réau','Reuil-en-Brie','Rouilly','Saint-Brice','Saint-Germain-Laval','Saint-Mammès','Saint-Martin-en-Bière','Saint-Pierre-lès-Nemours','Saint-Sauveur-sur-École','Samois-sur-Seine','Thomery','Varennes-sur-Seine','Vernou-la-Celle-sur-Seine','Villevaudé','Villiers-en-Bière','Voisenon'
        ]
    },
    '78':{
        label:'Yvelines',
        A:[
            "Achères","Andrésy","Aubergenville","Auffreville-Brasseuil","Aulnay-sur-Mauldre","Bazoches-sur-Guyonne","Bois-d'Arcy","Bougival","Buc","Buchelay","Carrières-sous-Poissy","Carrières-sur-Seine","La Celle-Saint-Cloud","Chambourcy","Chanteloup-les-Vignes","Chapet","Chevreuse","Les Clayes-sous-Bois","Coignières","Conflans-Sainte-Honorine","Élancourt","Épône","L'Étang-la-Ville","Évecquemont","La Falaise","Flins-sur-Seine","Follainville-Dennemont","Fontenay-le-Fleury","Gaillon-sur-Montcient","Gargenville","Guyancourt","Hardricourt","Houilles","Issou","Jouars-Pontchartrain","Jouy-en-Josas","Juziers","Limay","Les Loges-en-Josas","Louveciennes","Magnanville","Magny-les-Hameaux","Mantes-la-Jolie","Mantes-la-Ville","Mareil-Marly","Maurecourt","Maurepas","Médan","Le Mesnil-le-Roi","Le Mesnil-Saint-Denis","Meulan-en-Yvelines","Mézières-sur-Seine","Mézy-sur-Seine","Montesson","Montigny-le-Bretonneux","Les Mureaux","Neauphle-le-Château","Neauphle-le-Vieux","Nézel","Orgeval","Plaisir","Poissy","Porcheville","Le Port-Marly","Rambouillet","Saint-Cyr-l'École","Saint-Rémy-lès-Chevreuse","Saint-Rémy-l'Honoré","Sartrouville","Tessancourt-sur-Aubette","Trappes","Le Tremblay-sur-Mauldre","Triel-sur-Seine","Vaux-sur-Seine","Verneuil-sur-Seine","Vernouillet","La Verrière","Vert","Villennes-sur-Seine","Villepreux","Villiers-Saint-Frédéric","Voisins-le-Bretonneux"
        ],
        ABis:[
            "Chatou","Le Chesnay-Rocquencourt","Croissy-sur-Seine","Maisons-Laffitte","Marly-le-Roi","Le Pecq","Saint-Germain-en-Laye","Vélizy-Villacoublay","Versailles","Le Vésinet","Viroflay"
        ],
        B1:[
            'Aigremont','Les Alluets-le-Roi','Auffargis','Bailly','Bazemont','Bennecourt','Beynes','Boissy-Mauvoisin','Bonnières-sur-Seine','Bouafle','Breuil-Bois-Robert','Brueil-en-Vexin','Cernay-la-Ville','Châteaufort','Chavenay','Choisel','Crespières','Dampierre-en-Yvelines','Davron','Drocourt','Ecquevilly','Émancé','Les Essarts-le-Roi','Favrieux','Feucherolles','Fontenay-Mauvoisin','Fontenay-Saint-Père','Freneuse','Gazeran','Guernes','Guerville','Guitrancourt','Houdan','Jambville','Jouy-Mauvoisin','Lévis-Saint-Nom','Limetz-Villez','Mareil-le-Guyon','Maule','Ménerville','Méré','Méricourt','Les Mesnuls','Milon-la-Chapelle',"Montfort-l'Amaury",'Morainvilliers',"Mousseaux-sur-Seine","Noisy-le-Roi","Oinville-sur-Montcient","Perdreauville","Le Perray-en-Yvelines","Raizeux","Rennemoulin","Rolleboise","Rosny-sur-Seine","Saint-Forget","Saint-Germain-de-la-Grange","Saint-Hilarion","Saint-Lambert","Saint-Martin-la-Garenne","Saint-Nom-la-Bretèche","Saulx-Marchais","Senlisse","Soindres","Thiverval-Grignon","Toussus-le-Noble","Vicq"
        ]

    },
    '91':{
        label:'Essonne',
        A:[
            "Arpajon","Athis-Mons","Ballainvilliers","Bièvres","Bondoufle","Boussy-Saint-Antoine","Brétigny-sur-Orge","Breuillet","Breux-Jouy","Brunoy","Bruyères-le-Châtel","Bures-sur-Yvette","Champlan","Chilly-Mazarin","Corbeil-Essonnes","Le Coudray-Montceaux","Crosne","Dourdan","Draveil","Écharcon","Égly","Épinay-sous-Sénart","Épinay-sur-Orge","Étiolles","Évry-Courcouronnes","Fleury-Mérogis","Fontenay-le-Vicomte","Forges-les-Bains","Gif-sur-Yvette","Gometz-le-Châtel","Grigny","Igny","Juvisy-sur-Orge","Leuville-sur-Orge","Limours","Linas","Lisses","Longjumeau","Longpont-sur-Orge","Marcoussis","Massy","Mennecy","Montgeron","Montlhéry","Morangis","Morsang-sur-Orge","Morsang-sur-Seine","La Norville","Nozay","Ollainville","Ormoy","Orsay","Palaiseau","Paray-Vieille-Poste","Le Plessis-Pâté","Quincy-sous-Sénart","Ris-Orangis","Saclay","Saint-Aubin","Sainte-Geneviève-des-Bois","Saint-Germain-lès-Arpajon","Saint-Germain-lès-Corbeil","Saint-Michel-sur-Orge","Saint-Pierre-du-Perray","Saintry-sur-Seine","Saint-Yon","Saulx-les-Chartreux","Savigny-sur-Orge","Soisy-sur-Seine","Tigery","Varennes-Jarcy","Vauhallan","Verrières-le-Buisson","Vigneux-sur-Seine","Villabé","Villebon-sur-Yvette","La Ville-du-Bois","Villejust","Villemoisson-sur-Orge","Villiers-le-Bâcle","Villiers-sur-Orge","Viry-Châtillon","Wissous","Yerres","Les Ulis"
        ],
        B1:[
            "Auvernaux","Auvers-Saint-Georges","Avrainville","Ballancourt-sur-Essonne","Baulne","Boissy-sous-Saint-Yon","Boullay-les-Troux","Bouray-sur-Juine","Boutigny-sur-Essonne","Brières-les-Scellés","Briis-sous-Forges","Cerny","Chamarande","Champcueil","Cheptainville","Chevannes","Courdimanche-sur-Essonne","Courson-Monteloup","D'Huison-Longueville","Étampes","Étréchy","La Ferté-Alais","Fontenay-lès-Briis","Gometz-la-Ville","Guibeville","Guigneville-sur-Essonne","Itteville","Janville-sur-Juine","Janvry","Lardy","Leudeville","Marolles-en-Hurepoix","Mauchamps","Les Molières","Morigny-Champigny","Nainville-les-Roches","Pecqueuse","Roinville","Saint-Chéron","Saint-Jean-de-Beauregard","Saint-Maurice-Montcouronne","Saint-Sulpice-de-Favières","Saint-Vrain","Sermaise","Soisy-sur-École","Torfou","Vaugrigneuse","Vayres-sur-Essonne","Vert-le-Grand","Vert-le-Petit"
        ]
    },
    '95':{
        label:"Val-d'Oise",
        A:[
            "Andilly","Argenteuil","Arnouville","Auvers-sur-Oise","Beauchamp","Beaumont-sur-Oise","Bernes-sur-Oise","Bessancourt","Bezons","Boisemont","Bonneuil-en-France","Bouffémont","Butry-sur-Oise","Cergy","Champagne-sur-Oise","Cormeilles-en-Parisis","Courdimanche","Deuil-la-Barre","Domont","Eaubonne","Écouen","Épiais-lès-Louvres","Éragny","Ermont","Ézanville","Franconville","Frépillon","La Frette-sur-Seine","Garges-lès-Gonesse","Gonesse","Goussainville","Groslay","Herblay-sur-Seine","L'Isle-Adam","Jouy-le-Moutier","Margency","Menucourt","Mériel","Méry-sur-Oise","Montigny-lès-Cormeilles","Montlignon","Montmagny","Montmorency","Mours","Nesles-la-Vallée","Neuville-sur-Oise","Osny","Parmain","Persan","Pierrelaye","Piscop","Le Plessis-Bouchard","Pontoise","Puiseux-Pontoise","Roissy-en-France","Ronquerolles","Saint-Brice-sous-Forêt","Saint-Gratien","Saint-Leu-la-Forêt","Saint-Ouen-l'Aumône","Saint-Prix","Sannois","Sarcelles","Soisy-sous-Montmorency","Taverny","Le Thillay","Valmondois","Vaudherland","Vauréal","Villiers-Adam","Villiers-le-Bel"
        ],
        ABis:[
            "Enghien-les-Bains"
        ],
        B1:[
            "Ableiges","Aincourt","Asnières-sur-Oise","Attainville","Baillet-en-France","Béthemont-la-Forêt","Boissy-l'Aillerie","Bouqueval","Bruyères-sur-Oise","Chaumontel","Chauvry","Chennevières-lès-Louvres","Condécourt","Courcelles-sur-Viosne","Ennery","Fontenay-en-Parisis","Fosses","Frémainville","Frouville","Génicourt","Hédouville","Hérouville-en-Vexin","Labbeville","Livilliers","Longuesse","Louvres","Luzarches","Maffliers","Marly-la-Ville","Le Mesnil-Aubry","Moisselles","Montgeroult","Montsoult","Nerville-la-Forêt","Nointel","Le Plessis-Gassot","Presles","Puiseux-en-France","Sagy","Saint-Cyr-en-Arthies","Saint-Witz","Seraincourt","Seugy","Survilliers","Vallangoujard","Vémars","Vétheuil","Viarmes","Vienne-en-Arthies","Villeron"
        ]

    }

}
Object.freeze(DEPARTEMENTS);

export const TABLE_REVENUS: { [id: string]: { maxA: number, maxB1: number, maxB2: number } } = {
    '1': { maxA: 37581, maxB1: 37581, maxB2: 32673 },
    '2': { maxA: 56169, maxB1: 56169, maxB2: 43633 },
    '3': { maxA: 73630, maxB1: 67517, maxB2: 52471 },
    '4': { maxA: 87909, maxB1: 80875, maxB2: 63347 },
    '5': { maxA: 104592, maxB1: 95739, maxB2: 74518 },
    '6 ou plus': { maxA: 117694, maxB1: 107738, maxB2: 83983 }
}

Object.freeze(TABLE_REVENUS);