import { Step } from './Step';

export default function Step5() {
    return (
        <Step>
            <div style={{display:'flex', flexDirection:'column', gap:'40px', alignItems:'center'}}>
                <h2 className="step-title" style={{textAlign:'center'}}>Merci, votre demande a bien été envoyée</h2>
                <p style={{textAlign:'center'}}>Nous vous recontacterons dès que possible.</p>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <a href='https://coopfoncierefrancilienne.fr/'><button>{`Retourner à l'accueil`}</button></a>
                    <a href='https://coopfoncierefrancilienne.fr/nos-programmes/'><button>Voir nos programmes</button></a>
                </div>
            </div>
        </Step>
    );
}