import IUser from "../models/user";

export const modalStyles = {
    content: {
        height: 'auto',
        inset: 'auto',
        maxWidth: '70%',
        maxHeight: '80%',
        alignContent: 'center',
        justifyContent: 'start',
        AlignItems: 'center',
        FlexDirection: 'column',
        padding: '2rem',
    },
};

export const modalStyles2 = {
    content: {
        height: 'auto',
        inset: 'auto',
        maxWidth: '90%',
        maxHeight: '90%',
        alignContent: 'center',
        justifyContent: 'start',
        AlignItems: 'center',
        FlexDirection: 'column',
        padding: '1rem',
    },
};

export const disableButtonStep1 = (user:IUser) => {
    const condition = 
    user.achatCouple === undefined

    || (user.achatCouple === false && user.dependent === undefined)
    || (user.achatCouple === false && user.dependent === false && user.disableMe === undefined)
    || (user.achatCouple === false && user.dependent === true && user.dependentNumber === undefined)

    || (user.achatCouple === true && user.dependent === undefined)
    || (user.achatCouple === true && user.dependent === false && user.ageSum === undefined)
    || (user.achatCouple === true && user.dependent === false && user.ageSum === false && user.disable === undefined)
    || (user.achatCouple === true && user.dependent === true && user.dependentNumber === undefined)
    || (user.achatCouple === true && user.dependent === true && Number(user.dependentNumber) < 4 && user.disable === undefined)
    
    return condition
}