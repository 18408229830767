import defaultStepImage from '../Test-éligibilité.jpg'
import { IStepProps } from './type';

export function Step({imgSrc, children, imgAlt }: IStepProps) {

    return (
        <div className='imageContainer' style={{minHeight:'100vh', height:'100vh', width:'100vw', display:'flex'}} >
            <div className="left-container" style={{width:'50vw', display:'flex', alignItems:'center'}}>
                <img src={imgSrc||defaultStepImage} alt={imgAlt || 'Testez votre éligibilité au bail réél solidaire'} style={{width:'100%'}} />
            </div>

            <div className='mainContainer' style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between', height:'100vh', padding:'80px 20px', width:'50vw'}}>
                {children}        
            </div>

        </div>
    );
}
