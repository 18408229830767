import React, { useState } from "react";
import Modal from "react-modal"
import { Step } from "./Step";
import illustration from "../revenu-fiscal-reference.jpg"
import { Step2Props } from "./type";
import { ModalImage, Breadcrumb } from "./accessories";
import { SetStateAction } from "react";
import { modalStyles } from "./constante";

export default function Step2({ previousHandler, nextHandler, changeHandler, user }: Step2Props) {
    const [modalIsOpen, setIsOpen] = useState(false);

    const next = (e: React.MouseEvent<HTMLButtonElement>) => {
        nextHandler(e)
        window.scrollTo(0, 0)
    }

    return (
        <Step imgAlt="Testez votre éligibilité au bail réél solidaire">
            <Breadcrumb />
            <div>
                {user.achatCouple
                    ? <FiscalComponent text="Quel est le revenu fiscal de référence n-2 de mon ménage ?" setIsOpen={setIsOpen} />
                    : <FiscalComponent text="Quel est mon revenu fiscal de référence n-2 ?" setIsOpen={setIsOpen} />
                }
                <div className="answers">
                    <input placeholder="revenu fiScal" className="revenuFiscal" type="number" id="revenuFiscal" name="revenuFiscal" onChange={changeHandler} /><span className="unitForInput">euroS</span>
                </div>
            </div>

            <div className="inline-step-actions">
                <button onClick={previousHandler}>Retour</button>
                <button disabled={user.revenuFiscal === undefined} onClick={next}>Valider</button>
            </div>

            <Modal
                isOpen={modalIsOpen}
                style={modalStyles}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setIsOpen(false)}
                onAfterOpen={() => document.body.style.overflow = 'hidden'}
                onAfterClose={() => document.body.style.overflow = 'unset'}>
                <div style={{display:'flex', flexDirection:'column-reverse', alignItems:'center', gap:'20px'}}>
                    {user.achatCouple
                    ?
                    <p style={{maxWidth:'700px'}}>Votre revenu fiscal de référence N-2 se trouve sur votre avis d'imposition daté de l’année précédente. Par exemple en 2022, votre RFR N-2 se trouve sur votre avis d’impôts 2021. Si vous achetez à deux sans être mariés ou pacsés, n’oubliez pas d’additionner vos 2 RFR.</p>
                    :
                    <p style={{maxWidth:'700px'}}>Votre revenu fiscal de référence N-2 se trouve sur votre avis d'imposition daté de l’année précédente. Par exemple en 2022, votre RFR N-2 se trouve sur votre avis d’impôts 2021. </p>
                
                }
                    <img src={illustration} alt="où trouver son revenu N-2" style={{maxWidth:'95vw', width:'400px'}}/>
                </div>
                <div className="modal-footer" style={{ textAlign: "center" }}>
                    <button onClick={() => setIsOpen(false)}>J'ai compriS</button>
                </div>
            </Modal>
        </Step>
    )
}

function FiscalComponent ({text, setIsOpen}:{text:string, setIsOpen:React.Dispatch<SetStateAction<boolean>>}) {
    return(
        <div style={{display:'flex', flexDirection:'column-reverse', gap:'10px', alignItems:'center', maxWidth:'500px', marginBottom:'20px'}}>
            <p style={{textAlign:'center'}}>{text}</p>
            <ModalImage text='Où trouver ce montant ?' setIsOpen={setIsOpen} displayHelp={true} />
        </div>
    )
}