import { useState, useCallback, MouseEvent } from 'react';
import { GeoAPICommune, IStep4Props } from './type';
import { AutocompletePure, AutocompletePureProps, RenderItem, Theme } from "react-autocomplete-pure";
import { MainInput } from './accessories';
import { EachResume } from './accessories';
import close from '../close.svg'
import { CircularLoading } from '../utils/loader';

const renderItem: RenderItem<GeoAPICommune> = (item, { isHighlighted }) => (
    <span style={{ fontWeight: isHighlighted ? 700 : 400 }}>{item.nom}</span>
)
const autocompleteTheme: Theme = {
    container: "searchCity",
    list: "suggestionList",
    item: "suggestionItem"
}

export default function Step4({ user, nextHandler, changeHandler, reset, setUser }: IStep4Props) {
    
    const [inputVilles, setInputVilles] = useState<Array<string>>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<GeoAPICommune[]>([]);
    const [cityValue, setCityValue] = useState<string>('');
    const [err, setErr] = useState<boolean>(false)
    const [errRGPD, setErrRGPD] = useState<boolean>(false)
    const [requestErr, setRequestErr] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    async function sendEmail (e: MouseEvent<HTMLButtonElement>) {
        setRequestErr(false)
        setLoading(true)
        setErrRGPD(false)
        setErr(false)
        e.preventDefault();

        const { prenom, nom, email, rgpdChecked, rayonDeRecherche } = user;
        
        if (email === undefined || prenom === undefined || nom === undefined || inputVilles.length === 0 || rayonDeRecherche === undefined) {setErr(true); setLoading(false); return}
        if(!rgpdChecked) {setErrRGPD(true); setLoading(false); return}
        
        const data = [
            `${new Date().toLocaleDateString("fr")} ${new Date().toLocaleTimeString('fr')}`,
            prenom,
            nom,
            email,
            inputVilles.toString(),
            rayonDeRecherche,
            `${user.revenuFiscal}€`,
            `${user.achatCouple ? "À deux" : "Seul·e"}`,
            `${user.dependentNumber ? user.dependentNumber : 0}`,
            `${user.ageSum === undefined ? "" : user.ageSum === true ? "Oui" : ""}`,
            `${(user.disable === undefined) ? "" : user.disable === true ? "Oui" : ""} ${(user.disableMe === undefined) ? "" : user.disableMe === true ? "Oui" : ""}`
        ]

        try {
            const response = await fetch("https://api.coop.lucassoubry.fr/status", {
                method: "POST",    
                mode: "cors",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiY29vcGVyYXRpdmUyNCJ9.J3RJP1NPQDWP3kg9bxP380AjkyKhUMZlHHfegO4zNzo"
                },
                body: JSON.stringify(data)
            })
            const register = await response.json()
            console.log(register.message);
            if(register?.message === "Completed"){
                nextHandler(e);setLoading(false);setRequestErr(false)
            }
            setRequestErr(true)
            setLoading(false);
        } catch (e) {
            setRequestErr(true)
            setLoading(false);
            console.error('Error: ', e);
        }
    };

    //function to manage API (geo.api.gouv.fr)
    const handleChange: AutocompletePureProps<GeoAPICommune>["onChange"] = useCallback(
        async (_event, { value, reason }) => {
            setRequestErr(false)
            setCityValue(value);
            if (reason === "INPUT") {
                const cities = await fetchCities(value, inputVilles);
                if(cities === undefined) return
                setSuggestions(cities);
                setIsOpen(Boolean(cities.length));
            }
        },
        [inputVilles]
    );

    const handleSelect: AutocompletePureProps<GeoAPICommune>["onSelect"] = useCallback(
        (_event, { item }) => {
            const value = getSuggestionValue(item);
            setInputVilles((inputVilles) => [...inputVilles, value]);
            setCityValue('');
            setIsOpen(false);
        },
        []
    );

    const getSuggestionValue = (item: GeoAPICommune) => item.nom

    const fetchCities = async (searchStr: string, doublon:string[]) => {
        try{
            const response = await fetch(`https://geo.api.gouv.fr/communes?nom=${searchStr}&codeRegion=11&fields=departement`)
            if (!response.ok) return setRequestErr(true)
            const cities = await response.json()
            const filtereCities = cities.filter((city:any) => !doublon.includes(city.nom)).slice(0,5)
            return filtereCities
        } catch (e) {
            setRequestErr(true)
            console.error('Error: ', e);
        }
    }

    return (
        <section style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
            <div className="rappelDeMonProjet" style={{width:'100%'}}>
                <h2 className='titleStep5' style={{ textAlign: 'left', fontSize: '2.5rem', marginTop:0, marginBottom:'50px' }}><span style={{ color:'white'}}>Je Souhaite être contacté·e</span> par un opérateur de la coop foncière</h2>
                <section style={{display:'flex', flexDirection:'column'}}>
                    <h3 style={{marginTop:0}}>Rappel de mon projet</h3>
                    <div className='resume'>
                        <EachResume text="Achat : " result={`${user.achatCouple ? "À deux" : "Seul·e"}`} />
                        <EachResume text="Nombre de personnes à charge : " result={`${Number(user.dependentNumber) > 3? "4 ou pluS" : Number(user.dependentNumber)}`} display={Number(user.dependentNumber) > 0} />
                        <EachResume text="" result="Jeune ménage" display={user.ageSum === true}  />
                        <EachResume text="" result="1 personne ou pluS en Situation de handicap" display={(user.disable || user.disableMe) === true}  />
                        <EachResume text="Revenu fiscal de référence : " result={`${user.revenuFiscal} €`} />
                    </div>
                    <button style={{marginTop:'24px', width:'fit-content'}} onClick={() => reset()}>Refaire le teSt</button>
                </section>
            </div>
            <div className='finalForm'>
            {loading 
                ? <div style={{height:'80px', display:'flex', alignItems:'center'}}>
                    <CircularLoading /> 
                </div>
                :
                <>
                <div>
                    <h3 style={{marginTop:'35px'}}>Je complète ma demande</h3>
                    {requestErr ? <div className='errRgpd' style={{width:'fit-content', padding:'20px 0', placeSelf:'flex-start start', color:'rgb(239 68 68)', fontSize:'13px'}}>Une erreur est survenue, nous vous invitons à vérifier votre connexion internet ou réessayer plus tard.</div>: ''}
                    <p className="form-message">*Tous les champs sont obligatoires</p>
                </div>
                {err ? <div className='errRgpd' style={{width:'fit-content', padding:'20px 0', placeSelf:'flex-start start', color:'rgb(239 68 68)', fontSize:'13px'}}>Tous les champs doivent être remplis.</div>: ''}
                <form className='finalField'>
                    <MainInput placeholder='Mon prénom' name='prenom' type='text' changeHandler={changeHandler} required={true} />
                    <MainInput placeholder='Mon nom' name='nom' type='text' changeHandler={changeHandler} required={true} />
                    <MainInput placeholder='Mon email' name='email' type='email' changeHandler={changeHandler} required={true} />
                    <div className='listVilles'>
                        {inputVilles.map((inputField, index) => (
                            <span key={index} className="villeSelected">
                                <input name={`ville[${index}]`} key={index} value={inputField} readOnly />
                                <img onClick={() => setInputVilles(inputVilles.filter(x => x !== inputField))} src={close} alt="check pictogram" style={{width:'18px', margin:'5px 0', height:'auto', cursor:'pointer'}} />
                            </span>
                        ))
                        }
                        <AutocompletePure<GeoAPICommune>
                            className="city-search-input"
                            renderItem={renderItem}
                            placeholder='Ville(s) recherchée(s)'
                            getSuggestionValue={getSuggestionValue}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            open={isOpen}
                            value={cityValue}
                            items={suggestions}
                            onClickOutside={() => setIsOpen(false)}
                            autoComplete="new-password"
                            theme={autocompleteTheme}
                        />
                    </div>
                    <div className='kilometers'>
                        <label>Je peux élargir mon rayon de recherche de </label>
                        <select id="rayondeRecherche" name="rayonDeRecherche" onChange={changeHandler}>
                            {
                                [1, 5, 10, 15, 20].map((val, index) => (
                                    <option key={index} value={val}>{val} kilomètre{val > 1 ? 's' : ''}</option>
                                ))
                            }
                        </select>
                    </div>
                    {errRGPD ? <div className='errRgpd' style={{placeSelf:'flex-start start', color:'rgb(239 68 68)', fontSize:'13px'}}>Vous devez accepter nos politiques pour envoyer votre demande.</div>: ''}
                    <div style={{display:'flex', gap:'10px'}} className='checkboxFied'>
                        <input id="rgpdChecked" style={{minWidth:'17px', cursor:'pointer', height:'auto'}} type="checkbox" checked={user.rgpdChecked} name="rgpdChecked" onChange={() => setUser({ ...user, rgpdChecked: !user.rgpdChecked })} />
                        <label htmlFor="rgpdChecked">En cochant cette case, j’autorise La Coop Foncière à conserver mes données personnelles et les utiliser à des fins d’informations sur les offres en BRS ainsi qu’à des fins d’études statistiques.</label>
                    </div>
                    <button type='submit' className="primary" style={{ marginBottom: "3rem" }} onClick={sendEmail}>Je SoumetS ma demande</button>
                </form>
            </>
            }
            </div>
        </section>
    );
}

// 


