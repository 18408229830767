import { MouseEvent, MouseEventHandler, useState } from "react";
import { Step } from "./Step";
import { Step1Props } from "./type";
import { SubStep, RadioInput, Breadcrumb } from "./accessories";
import { clearState } from "../utils/reset";
import IUser from "../models/user";
import { disableButtonStep1 } from "./constante";
import { person_number } from "../utils/calculation";

export default function Step1({ nextHandler, user, setUser, setNumberPerson }: Step1Props) {

    const [fieldNumber, setFieldNumber] = useState<number>(0)

    const next: MouseEventHandler = (e:MouseEvent<HTMLButtonElement>) => {
        nextHandler(e)
    }

    return (
        <Step imgAlt="Testez votre éligibilité au bail réél solidaire">
            <Breadcrumb />
            <div className="fieldContainer" style={{display:'flex', flexDirection:'column', gap:'60px', flex:1, justifyContent:'center'}}>
                <SubStep text="Je prévois d'acheter mon futur logement ?" displayProperty={false} setFieldNumber={setFieldNumber} fieldNumber={fieldNumber}>
                    <RadioInput text="Seul·e" value="false" customId="solo" name="achatCouple" check={String(user.achatCouple) === 'false'} changeHandler={() => {
                        clearState(setUser);
                        setUser((currentUser:IUser) => ({...currentUser, achatCouple: false }))
                        }}  />
                    <RadioInput text="À deux" value="true" customId="couple" name="achatCouple" check={String(user.achatCouple) === 'true'} changeHandler={() => {
                        clearState(setUser)
                        setUser((currentUser:IUser) => ({...currentUser, achatCouple: true }))
                        }}  />
                </SubStep>

                <SubStep text='Avez-vous des personnes à charge ?' 
                         setFieldNumber={setFieldNumber} fieldNumber={fieldNumber}
                         displayProperty={user.achatCouple === undefined} 
                         displayHelp={true}>
                    <RadioInput text="Oui" value="true" customId="dependentTrue" name="dependent" check={String(user.dependent) === 'true'} changeHandler={() => {
                        clearState(setUser)
                        setUser((currentUser:IUser) => ({...currentUser, achatCouple:user.achatCouple, dependent: true }))
                        }} />
                    <RadioInput text="Non" value="false" customId="dependentFalse" name="dependent" check={String(user.dependent) === 'false'} changeHandler={() => {
                        clearState(setUser)
                        setUser((currentUser:IUser) => ({...currentUser, achatCouple:user.achatCouple, dependent: false }))
                        }}  />
                </SubStep>

                <SubStep text='Combien ?' 
                         displayProperty={ 
                            (user.dependent === undefined || user.achatCouple === undefined) 
                            || (user.dependent === false)
                         }
                         setFieldNumber={setFieldNumber} fieldNumber={fieldNumber}>
                    <RadioInput text="1" value="1" customId="one" name="dependentNumber" check={Number(user.dependentNumber) === 1} changeHandler={() =>
                        setUser({...user, dependentNumber:1})} />
                    <RadioInput text="2" value="2" customId="two" name="dependentNumber" check={Number(user.dependentNumber) === 2} changeHandler={() =>
                        setUser({...user, dependentNumber:2})}  />
                    <RadioInput text="3" value="3" customId="three" name="dependentNumber" check={Number(user.dependentNumber) === 3} changeHandler={() =>
                        setUser({...user, dependentNumber:3})}  />
                    <RadioInput text="4 ou pluS" value="4" customId="four" name="dependentNumber" check={Number(user.dependentNumber) === 4} changeHandler={() => {
                        clearState(setUser);
                        setUser((currentUser:IUser) => ({...currentUser, achatCouple:user.achatCouple, dependent:user.dependent, dependentNumber:4 }))
                        }}  />
                </SubStep>

                <SubStep text='Suis-je en situation de handicap ?'
                         displayProperty={ 
                            (user.achatCouple === true || user.achatCouple === undefined) 
                            || (user.dependent === true || user.dependent === undefined)
                         }
                         setFieldNumber={setFieldNumber} fieldNumber={fieldNumber}>
                    <RadioInput text="Oui" value="true" customId="disableMeTrue" name="disableMe" check={String(user.disableMe) === 'true'} changeHandler={() => setUser({ ...user, disableMe: true })}  />
                    <RadioInput text="Non" value="false" customId="disableMeFalse" name="disableMe" check={String(user.disableMe) === 'false'} changeHandler={() => setUser({ ...user, disableMe: false })}  />
                </SubStep>

                <SubStep text='La somme de nos deux âges est-elle inférieure ou égale à 55 ans ?' 
                         displayProperty={
                            (user.achatCouple === undefined || user.dependent === undefined)
                            || user.achatCouple === false 
                            || user.dependent === true
                         }
                         setFieldNumber={setFieldNumber} fieldNumber={fieldNumber}>
                    <RadioInput text="Oui" value="true" customId="ageSumTrue" name="ageSum" check={String(user.ageSum) === 'true'} changeHandler={() => {
                        clearState(setUser);
                        setUser((currentUser:IUser) => ({...currentUser, achatCouple:user.achatCouple, dependent:user.dependent, ageSum:true }))
                        }}  />
                    <RadioInput text="Non" value="false" customId="ageSumFalse" name="ageSum" check={String(user.ageSum) === 'false'} changeHandler={() => setUser({ ...user, ageSum: false })}  />
                </SubStep>

                <SubStep text='Y aura t-il une ou plusieurs personnes en situation de handicap dans le logement ?' 
                         displayProperty={
                            (user.achatCouple === undefined || user.dependent === undefined)
                            || (user.achatCouple === false)
                            || (user.dependent === false && (user.ageSum === true || user.ageSum === undefined))
                            || (user.dependent === true  && (Number(user.dependentNumber) > 3 || user.dependentNumber === undefined))
                         }
                         setFieldNumber={setFieldNumber} fieldNumber={fieldNumber}>
                    <RadioInput text="Oui" value="true" customId="disableTrue" name="disable" check={String(user.disable) === 'true'} changeHandler={() => setUser({ ...user, disable: true })}  />
                    <RadioInput text="Non" value="false" customId="disableFalse" name="disable" check={String(user.disable) === 'false'} changeHandler={() => setUser({ ...user, disable: false })}  />
                </SubStep>
            </div>
            <button style={{margin:0}} disabled={disableButtonStep1(user)} onClick={(e) => {person_number(user, setNumberPerson);next(e)}}>PaSSer à l'étape Suivante</button>
        </Step>
    )

}

