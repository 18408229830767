import { RadioInputProps, SubStepProps, ModalImageProps, GlobalInputProps } from "./type";
import { useSteps } from "react-step-builder";
import informationIco from "../information-ico.svg"
import rightArrow from "../right-arrow.svg"
import { useEffect, useState } from "react";
import Modal from "react-modal"
import { modalStyles } from "./constante";
import check from '../check.svg'

export function RadioInput ({text, customId, name, value, check, changeHandler,}:RadioInputProps) {
    return(
        <label className="input-container" htmlFor={customId} style={{display:'flex', alignItems:'center', gap:'3px'}}>
            <input
                type="radio"
                id={customId}
                name={name}
                value={value}
                checked={check}
                onChange={changeHandler}
            />
            <span className="checkmark"></span>
            {text}
        </label>
    )
}

export function MainInput ({placeholder, name, changeHandler, required, type}:GlobalInputProps){
    return(
        <input placeholder={placeholder} required={required} type={type} id={name} name={name} onChange={changeHandler} />
    )
}

export function Breadcrumb () {
    const { current } = useSteps();
    return(
        <div className="steps-breadcrumb">
            <div>
                <p className={current === 1 ? 'active' : ''}>étape 1</p>
                <img src={rightArrow} alt="right arrow pictogram" />
            </div>
            <div>
                <p className={current === 2 ? 'active' : ''}>étape 2</p>
                <img src={rightArrow} alt="right arrow pictogram" />
            </div>
            <div>
                <p className={current === 3 ? 'active' : ''}>étape 3</p>
                <img src={rightArrow} alt="right arrow pictogram" />
            </div>
        </div>
    )
}

export function SubStep({children, text, displayProperty, displayHelp=false, fieldNumber, setFieldNumber}:SubStepProps) {
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);
    useEffect(() => {
        if(!displayProperty) {
            setFieldNumber(fieldNumber + 1)
        } else {
            setFieldNumber(fieldNumber)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayProperty])

    return (
        <div style={displayProperty
            ? {display:'none'}
            : {display:'flex', flexDirection:'column', gap:'15px', alignItems:'center'}}>
            <div style={{display:'flex'}}>
                <p style={{textAlign:'center'}}>{text}</p>
                <ModalImage text='' setIsOpen={setIsOpen} displayHelp={displayHelp} />
            </div>
            <div style={{display:'flex', gap:'25px', flexWrap:'wrap', justifyContent:'center'}}>
                {children}
            </div>
            <Modal
                isOpen={modalIsOpen}
                style={modalStyles}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setIsOpen(false)}
                onAfterOpen={() => document.body.style.overflow = 'hidden'}
                onAfterClose={() => document.body.style.overflow = 'unset'}>
                <div style={{display:'flex', flexDirection:'column', gap:'10px', marginBottom:'20px' }}>
                    <p style={{margin:0}}>Sont réputées personnes à charge :</p>
                    <p style={{margin:0}}>1° Les enfants qui n'ont pas établi une déclaration au titre de l'impôt sur le revenu en leur nom propre ;</p>
                    <p style={{margin:0}}>{`2° a) Les ascendants qui sont âgés d'au moins soixante-cinq ans ou de soixante ans en cas d'inaptitude au travail et dont les ressources ne sont pas passibles de l'impôt sur le revenu ;`}</p>
                    <p style={{margin:0}}>{`b) Les ascendants, descendants ou collatéraux au deuxième ou troisième degré titulaires de la carte d'invalidité dont les ressources ne sont pas passibles de l'impôt sur le revenu.`}</p>
                </div>
                <div className="modal-footer" style={{ textAlign: "center" }}>
                    <button onClick={() => setIsOpen(false)} >J'ai compriS</button>
                </div>
            </Modal>
        </div>
    );
}

export function ModalImage ({text, setIsOpen, displayHelp}:ModalImageProps) {
    return(
        <button className="link-button en-savoir-plus" onClick={() => {setIsOpen(true)}} style={displayHelp ? {display:'flex', alignItems:'center', gap:'10px', textDecoration:'none'} : {display:'none'}}>
            <img style={{ width: "2rem", height: "2rem" }} src={informationIco} alt="information" />
            <p style={{fontSize:'0.7rem', color:'rgb(75 85 99)', textDecoration:'underline', textUnderlineOffset:3}}>{text}</p>  
        </button>
    )
}  

export function EachResume ({text, result, display=true}:{text:string, result:string, display?:boolean}) {
    return(
        <div style={(display && display !== undefined) ? {display:'flex', alignItems:'center', gap:'8px'} : {display:'none'}}>
            <div style={{backgroundColor:'#00454f', minWidth:'26px', width:'26px', borderRadius:'50%',minHeight:'26px', height:'26px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <img src={check} alt="check pictogram" style={{width:'18px', height:'auto'}} />
            </div>
            <p style={{ display: 'inline' }}>{text}<span className="userData">{result}</span></p>
        </div>
    )
}