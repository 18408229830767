import { createTheme } from "@mui/material";

export const colors = {
    primary: "#00454f",
    secondary: "#19A370"
}

export const colorTheme = createTheme({
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.primary,
        },
    }
})