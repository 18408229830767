import { useEffect, useState } from 'react';
import { Step } from './Step';
import close from "../close.svg"
import { IStep3Props, zoneBRS, RawTablepressResponse, HashTableRevenus, ApiResponse } from './type';
import Modal from 'react-modal'
import { DEPARTEMENTS, TABLE_REVENUS } from '../constants'
import { modalStyles2 } from './constante';
import { CircularLoading } from '../utils/loader';
// import reportWebVitals from '../reportWebVitals';

const siteUrl = `https://coopfoncierefrancilienne.fr`
const tableId = 2

export default function Step3({ user, nextHandler, reset, numberPerson }: IStep3Props) {
    const [eligibilite, setEligibilite] = useState<zoneBRS>('NOT')
    const [isOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [rawData, setRawData] = useState<RawTablepressResponse>([])
    const [departement, setDepartement] = useState('')
    const { v4: uuidv4 } = require('uuid')

    const [currentDep, setCurrentDep] = useState<string[]>([])
    const [searchDep, setSearchDep] = useState<string[]>([])
    const [search, setSearch] = useState('')

    //Set message according to revenus & persons
    const eligibiliteMessage = (zone: zoneBRS) => {
        switch (zone) {
            case 'B2':
                return <p style={{textAlign:'center'}}>Selon les informations que vous avez renseignées, <span className="green">vous semblez éligible au Bail Réél Solidaire sur toutes les communes d'Ile de France. </span></p>
            case 'B1':
                return <p style={{textAlign:'center'}}>Selon les informations que vous avez renseignées, <span className="green">vous semblez éligible au Bail Réel Solidaire sur Paris, les communes de la petite couronne (92, 93, 94) et certaines communes de la grande couronne.</span></p>
            case 'A':
                return <p style={{textAlign:'center'}}>Selon les informations que vous avez renseignées, <span className="green">vous semblez éligible au Bail Réel Solidaire sur Paris, les communes de la petite couronne (92, 93, 94) et certaines communes de la grande couronne.</span></p>
            default:
                return <p style={{textAlign:'center'}}>Malheureusement, selon les informations que vous avez renseignées, vos revenus semblent trop importants pour être éligible au dispositif du Bail Réel Solidaire</p>
        }
    }

    // GET table from WP
    useEffect(() => {
        async function fetchTableRevenus() {
            setIsLoading(true);
            try {
                const response = await fetch(`${siteUrl}/wp-json/testeligibilite/v1/public/tables/${tableId}`)
                const json = await response.json() as ApiResponse
                if (!response.ok) {
                    throw new Error(json.message);
                }
                setRawData(json.data)
            } catch (e: any) {
                setError(e.message)
            } finally {
                setIsLoading(false);
            }
        }
        fetchTableRevenus()
        return () => {setRawData([]); setError(''); setIsLoading(false)}
    }, [])

    useEffect(() => {
        const { revenuFiscal } = user
        //return object for each row (= number of people) title and his corresponding value 
        if (rawData.length > 0) {
            let tableRevenus: HashTableRevenus = {}
            const headers = rawData.at(0)
            if (headers) {
                //array with each line in array except line one (with title)
                tableRevenus = rawData.slice(1).reduce((acc: HashTableRevenus, row: string[]) => {
                    //number of person
                    const id = row.at(0)
                    //take a line, cut first element (= id)
                    const values = row.slice(1)
                    if (id !== undefined) {
                        //for each case (initially number) return header title with corresponding number
                        acc[id] = headers.slice(1).reduce((obj: any, header: string, i: number) => {
                            obj[header] = Number(values[i])
                            return obj
                        }, {})
                    }
                    return acc
                }, {})
            }
            
            if (numberPerson === undefined || revenuFiscal === undefined) { 
                setEligibilite('NOT') 
            } else {
                const { maxA, maxB1, maxB2 } = tableRevenus[numberPerson]
                if (revenuFiscal <= maxA && revenuFiscal > maxB1) {
                    setEligibilite('A')
                } else if (revenuFiscal <= maxB1 && revenuFiscal > maxB2) {
                    setEligibilite('B1')
                } else if (revenuFiscal <= maxB2) {
                    setEligibilite('B2')
                } else {
                    setEligibilite('NOT')
                }
            }
        }
    }, [user, rawData, numberPerson]);

    //GET all cities according to eligibity
    useEffect(() => {
        if(eligibilite === 'B1') {
            if(departement !== '') return setCurrentDep([...new Set([DEPARTEMENTS[departement].A, DEPARTEMENTS[departement].ABis, DEPARTEMENTS[departement].B1])].flat())
        }
        if(eligibilite === 'A') {
            if(departement !== '') return setCurrentDep([...new Set([DEPARTEMENTS[departement].A, DEPARTEMENTS[departement].ABis])].flat())
        }
    }, [eligibilite, departement])

    //search fucntion
    useEffect(() => {
        if(currentDep === undefined) return setSearchDep([])
        setSearchDep(currentDep?.sort(function (a, b) {
            return a.localeCompare(b);
          }).filter(dep => dep?.toLowerCase().includes(search?.toLowerCase())))
    }, [currentDep, search])

    if (error || numberPerson === 0) {
        return <p>Error, {error}</p>;
    }

    return (
        <Step>
            {isLoading
            ? <CircularLoading />
            : error || numberPerson === 0 
                ? 
                <div>
                    <h2 className="step-title" style={{textAlign:'center'}}>RéSultat du teSt</h2>
                    <div className='errRgpd' style={{placeSelf:'start start', width:'fit-content', padding:'20px 0', color:'rgb(239 68 68)', fontSize:'13px'}}>Une erreur est survenue, nous vous invitons à vérifier votre connexion internet ou réessayer plus tard.</div>
                </div>
            :
            <>
            <h2 className="step-title" style={{textAlign:'center'}}>RéSultat du teSt</h2>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', gap:'20px'}}>
                    {eligibiliteMessage(eligibilite)}
                    {(eligibilite === 'A' || eligibilite === 'B1') &&
                        <ul className="communes-list-link">
                            {['77', '78', '91', '95'].map((dep) => {
                                return (
                                    <li key={dep} style={{ fontWeight: "400" }}>
                                        <button className="link-button" onClick={() => {setDepartement(dep);setIsOpen(true)}}>{DEPARTEMENTS[dep].label}</button>
                                    </li>
                                )
                            })}
                        </ul>
                    }
                    {eligibilite !== 'NOT' &&
                        <p style={{textAlign:'center'}}>Acceptez-vous d’être contacté·e lors de nos prochaines commercialisations?</p>
                    }
                </div>
                <div className="step-action">
                    {eligibilite === 'NOT' ?
                        <button disabled={eligibilite !== 'NOT'} onClick={() => {setDepartement('');setIsOpen(true)}}>Voir leS plafondS</button>
                        :
                        <button onClick={(e) => {nextHandler(e);window.scrollTo(0, 0)}}>Oui, je complète ma demande</button>
                    }
                    <button className="secondary" onClick={() => reset()}>Je refaiS le teSt</button>
                </div>

                <Modal  onAfterOpen={() => document.body.style.overflow = 'hidden'}
                        onAfterClose={() => document.body.style.overflow = 'unset'}
                        isOpen={isOpen} preventScroll={true} ariaHideApp={false} style={modalStyles2}
                        shouldCloseOnOverlayClick={true} onRequestClose={() => setIsOpen(false)}>
                    
                    {departement !== '' 
                        ?   <div className='citiesModal'>
                                <img src={close} alt="close pictogram" style={{width:'35px', height:'auto', position:'absolute', top:'10px', right:'20px', cursor:'pointer'}} onClick={() => setIsOpen(false)} />
                                <div className='citiesSearch'>
                                    <input name='cities' type="text" placeholder='Nom de la commune...' value={search} onChange={(e) => setSearch(e.target.value)} />
                                </div>
                                <div className='citiesTable'>
                                {searchDep.length === 0 
                                    ? <p>Malheureusement cette ville ne fait pas partie de votre zone d’éligibilité</p>
                                    : searchDep.map(dep => <p key={uuidv4()}>{dep}</p>)
                                }
                                </div>
                            </div>
                        :
                        <>
                            <h2><span className="green">Conditions de revenuS</span><br />pour bénéficier du Bail Réél Solidaire</h2>
                            <table>
                                <thead><tr>
                                    <th>Nombre de personnes occupant le logement</th>
                                    <th>Zone A & Zone ABis</th>
                                    <th>Zone B1</th>
                                    <th>Zone B2</th>
                                </tr></thead>
                                <tbody>
                                    {Object.entries(TABLE_REVENUS).map((entry) => {
                                        const [nbP, item] = entry
                                        return (
                                            <tr key={nbP}>
                                                <td>{nbP} {nbP === '6 ou plus' ? '' : nbP !== '1' ? 'personnes' : 'personne'} {}</td>
                                                <td className="montant">{String(item.maxA).slice(0, String(item.maxA).length-3)} {String(item.maxA).slice(String(item.maxA).length-3, String(item.maxA).length)} €</td>
                                                <td className="montant">{String(item.maxB1).slice(0, String(item.maxB1).length-3)} {String(item.maxB1).slice(String(item.maxB1).length-3, String(item.maxB1).length)} €</td>
                                                <td className="montant">{String(item.maxB2).slice(0, String(item.maxB2).length-3)} {String(item.maxB2).slice(String(item.maxB2).length-3, String(item.maxB2).length)} €</td>
                                            </tr>
                                        )})
                                    }
                                </tbody>
                            </table>
                        </>
                    }
                    <div className="modal-footer" style={{ textAlign: "center" }}>
                        <button onClick={() => setIsOpen(false)}>J'ai compriS</button>
                    </div>
                </Modal>
            </>
            }
        </Step >
    );
}