const initialState = {
    villes: [], 
    rayonDeRecherche: 1,
    rgpdChecked: false, 
    dependent:undefined, 
    dependentNumber: undefined, 
    disableMe:undefined, 
    disable:undefined, 
    ageSum:undefined, 
    achatCouple:undefined
}

export function clearState (setUser) {
    setUser(initialState);
}